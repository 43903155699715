import React from "react"

const InlineVideo = (props) => {
  const { url } = props
  
  return (
    <>
      <video src={url} playsInline muted loop autoPlay {...props}></video>
    </>
  )
}

export default InlineVideo