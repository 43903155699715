import React from "react"
import CustomLink from "./customLink"

const CtaCallout = ({ data }) => {
  function hasLink() {
    return data.primary.link && data.primary.link_text.text
  }

  return (
    <section className={`cta-callout bg-${data.primary.background_color}`}>
      <div className="inner">
        <div className="row">
          <div className="col text" data-sal="slide-up" data-sal-delay="0">
            <h3>{data.primary.title.text}</h3>
            <h4>{data.primary.subtitle.text}</h4>

            {hasLink() && (
              <CustomLink link={data.primary.link} className="button violet">
                {data.primary.link_text.text}
              </CustomLink>
            )}
          </div>

          {data.primary.image.url && (
            <div className="col asset" data-sal="fade" data-sal-delay="300">
              <img src={data.primary.image.url} alt={data.primary.image.alt} />
            </div>
          )}
        </div>
      </div>
    </section>
  )
}

export default CtaCallout
