import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { Tab, Tabs, TabList, TabPanel } from "react-tabs"

import Layout from "../components/layout"
import SEO from "../components/seo"
import InlineVideo from "../components/inlineVideo"
import SimpleHero from "../components/simpleHero"
import CtaCallout from "../components/ctaCallout"
import CustomLink from "../components/customLink"

const ProductPage = () => {
  let data = useStaticQuery(graphql`
    query ProductPageQuery {
      prismicProductPage: prismicProductPage {
        data {
          hero_title {
            text
            html
          }
          hero_text {
            text
            html
          }
          hero_link {
            url
            target
            type
            link_type
            uid
          }
          hero_link_text {
            html
            text
          }
          hero_image {
            url
            fluid(maxWidth: 800) {
              ...GatsbyPrismicImageFluid
            }
          }
          hero_image_alt {
            url
          }
          sensor_features_headline {
            text
            html
          }
          sensor_features {
            feature_image {
              fluid(maxWidth: 600) {
                ...GatsbyPrismicImageFluid
              }
            }
            feature_video {
              url
              link_type
            }
            feature_info {
              text
              html
            }
            feature_link {
              url
              target
              link_type
            }
            feature_link_text {
              text
              html
            }
            feature_list {
              html
              text
            }
            feature_name {
              text
              html
            }
          }
          platform_features_headline {
            text
            html
          }
          platform_features {
            feature_headline {
              text
              html
            }
            feature_image {
              fluid(maxWidth: 600) {
                ...GatsbyPrismicImageFluid
              }
            }
            feature_video {
              url
              link_type
            }
            feature_info {
              text
              html
            }
            feature_name {
              text
              html
            }
            feature_link {
              link_type
              url
              uid
              type
              target
            }
            feature_link_text {
              text
              html
            }
          }
          capabilities_headline {
            text
            html
          }
          capabilities {
            capability_icon {
              fluid(maxWidth: 300) {
                ...GatsbyPrismicImageFluid
              }
            }
            capability_info {
              text
              html
            }
            capability_name {
              html
              text
            }
          }
          body {
            ... on PrismicProductPageBodyCtaCallout {
              id
              primary {
                background_color
                title {
                  text
                  html
                }
                subtitle {
                  text
                  html
                }
                link {
                  link_type
                  url
                  uid
                  type
                  target
                }
                link_text {
                  text
                  html
                }
                image {
                  url
                }
              }
            }
          }
        }
      }
    }
  `).prismicProductPage.data
  if (!data) return null

  if (
    typeof window !== "undefined" &&
    window.__PRISMIC_PREVIEW_DATA__ &&
    window.__PRISMIC_PREVIEW_DATA__.prismicProductPage
  ) {
    data = window.__PRISMIC_PREVIEW_DATA__.prismicProductPage.data
  }

  const ctaCalloutSlice = data.body.find(
    s => s.__typename === "PrismicProductPageBodyCtaCallout"
  )

  return (
    <Layout mainClass="product-page">
      <SEO title="Product" />

      <SimpleHero data={data} useBackgroundImage={false} />

      <section className="product-hero-spacer hero two">
        <div className="row spacer">
          <div className="inner">
            <div className="col"></div>
            <div className="col"></div>
          </div>
        </div>
      </section>

      {ctaCalloutSlice && <CtaCallout data={ctaCalloutSlice} />}

      <section className="product-features">
        <div className="product-platform-features">
          <div className="inner">
            <h3 data-sal="slide-up" data-sal-delay="300">
              {data.platform_features_headline.text}
            </h3>

            <Tabs>
              <TabList data-sal="slide-up" data-sal-delay="300">
                {data.platform_features.map((feature, ind) => {
                  if (!feature.feature_name) return null

                  return <Tab key={ind}>{feature.feature_name.text}</Tab>
                })}
              </TabList>

              {data.platform_features.map((feature, ind) => {
                if (!feature.feature_headline) return null

                return (
                  <TabPanel key={ind}>
                    <div
                      className="feature"
                      key={`product-platform-features-${ind}`}
                    >
                      <div className="col asset">
                        {feature.feature_video &&
                          feature.feature_video.url === "" && (
                            <Img
                              fluid={feature.feature_image.fluid}
                              alt={feature.feature_headline.text}
                            />
                          )}

                        {feature.feature_video &&
                          feature.feature_video.url !== "" && (
                            <InlineVideo
                              url={feature.feature_video.url}
                              height="310"
                            />
                          )}
                      </div>

                      <div className="col text">
                        <h5>{feature.feature_headline.text}</h5>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: feature.feature_info?.html,
                          }}
                        ></div>

                        {feature.feature_link &&
                          feature.feature_link_text.text && (
                            <CustomLink
                              link={feature.feature_link}
                              className="button violet"
                            >
                              {feature.feature_link_text.text}
                            </CustomLink>
                          )}
                      </div>
                    </div>
                  </TabPanel>
                )
              })}
            </Tabs>
          </div>
        </div>

        <div className="product-sensor-features">
          <div className="inner">
            <h3 data-sal="slide-up" data-sal-delay="300">
              {data.sensor_features_headline.text}
            </h3>
            {data.sensor_features.map((feature, ind) => {
              if (!feature.feature_name) return null

              return (
                <div className="feature" key={`product-sensor-features-${ind}`}>
                  <div
                    className="col asset"
                    data-sal="slide-up"
                    data-sal-delay="300"
                  >
                    {feature.feature_video &&
                      feature.feature_video.url === "" && (
                        <Img
                          fluid={feature.feature_image.fluid}
                          alt={feature.feature_name.text}
                        />
                      )}

                    {feature.feature_video &&
                      feature.feature_video.url !== "" && (
                        <InlineVideo url={feature.feature_video.url} />
                      )}
                  </div>

                  <div
                    className="col text"
                    data-sal="slide-up"
                    data-sal-delay="600"
                  >
                    <h5>{feature.feature_name.text}</h5>
                    <p>{feature.feature_info?.text}</p>
                    {feature.feature_list.text !== "" && (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: feature.feature_list?.html,
                        }}
                      ></div>
                    )}

                    {feature.feature_link.url !== "" && (
                      <a
                        href={feature.feature_link?.url}
                        className="button violet"
                      >
                        {feature.feature_link_text?.text}
                      </a>
                    )}
                  </div>
                </div>
              )
            })}
          </div>
        </div>

        <div className="product-capabilities">
          <div className="inner">
            <h3>{data.capabilities_headline.text}</h3>
            <div className="capabilities">
              {data.capabilities.map((capability, ind) => {
                if (!capability.capability_name) return null

                return (
                  <div className="capability" key={`product-capability-${ind}`}>
                    <div
                      className="col asset"
                      data-sal="slide-up"
                      data-sal-delay={`${ind++ * 150}`}
                    >
                      <Img
                        fluid={capability.capability_icon.fluid}
                        alt={capability.capability_name.text}
                      />
                    </div>

                    <div
                      className="col text"
                      data-sal="slide-up"
                      data-sal-delay={`${ind++ * 150}`}
                    >
                      <h5>{capability.capability_name.text}</h5>
                      <p>{capability.capability_info?.text}</p>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default ProductPage
