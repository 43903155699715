import React from "react"

import CustomLink from "./customLink"
import { triggerRequestDemoClick } from "./_helpers/triggerRequestDemoClick"

const SimpleHero = props => {
  const {
    hero_title,
    hero_text,
    hero_image,
    hero_image_alt,
    hero_link,
    hero_link_text,
  } = props.data

  const { useBackgroundImage = true } = props

  const hasHeroLink = () => {
    return hero_link && hero_link.url !== "" && hero_link_text.text !== ""
  }

  return (
    <section
      className={`simple-hero hero ${
        useBackgroundImage ? "has-background-image" : ""
      }`}
      style={
        useBackgroundImage
          ? {
              backgroundImage: `url(${hero_image.url})`,
            }
          : {}
      }
      data-sal="fade"
      data-sal-delay="0"
    >
      <div className="row">
        <div className="inner">
          <div className="col text" data-sal="slide-up" data-sal-delay="0">
            <div dangerouslySetInnerHTML={{ __html: hero_title.html }}></div>
            <p>{hero_text.text}</p>

            {hasHeroLink() && (
              <CustomLink
                link={hero_link}
                className="button violet"
                data-sal="slide-up"
                data-sal-delay="600"
              >
                {hero_link_text.text}
              </CustomLink>
            )}

            {!hasHeroLink() && (
              <button
                onClick={triggerRequestDemoClick}
                className="button violet"
                data-sal="slide-up"
                data-sal-delay="600"
              >
                Request Demo
              </button>
            )}
          </div>

          {!useBackgroundImage && (
            <div className="col asset" data-sal="slide-up" data-sal-delay="300">
              <img src={hero_image.url} alt="" />
            </div>
          )}
        </div>
      </div>

      {hero_image_alt && (
        <div className="row">
          <div
            className="col asset mobile"
            data-sal="slide-up"
            data-sal-delay="0"
          >
            <img src={hero_image_alt.url} alt="" />
          </div>
        </div>
      )}
    </section>
  )
}

export default SimpleHero
